<template>
  <VDialog
    content-class="cross__dialog"
    v-model="visibility"
    max-width="500px"
    :persistent="isChanged"
    @click:outside="showConfirmDialog"
    scrollable
    :transition="$dialogTransition"
  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? '500' : ''">
      <div class="cross__inner">
        <VBtn fab small  elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <span class="font-20" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">
            {{$t('form.adding')}} {{ addName }}
        </span>
      </VCardTitle>
      <VDivider />
      <VCardText>
        <VContainer class="py-0">
          <VRow :class="$vuetify.breakpoint.smAndDown ? 'align-center mt-3 justify-center' : 'align-start mt-3 justify-start'">
            <VCol cols="12" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'flex'">
              <VRow v-if="!photoPreview && !$vuetify.breakpoint.smAndDown">
                <VCol cols="3">
                  <img
                    src="@/assets/images/account-photo.svg"
                    alt="account-photo.svg"
                  />
                </VCol>
                <VCol cols="6" class="align-self-center">
                  <label for="customer_photo">
                    <VBtn :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`" @click="getFile">
                      {{ $t("profile.changeBtn") }}
                    </VBtn>
                  </label>
                </VCol>
              </VRow>
              <VRow v-if="!photoPreview && $vuetify.breakpoint.smAndDown">
                <VCol cols="12">
                  <img
                    src="@/assets/images/account-photo.svg"
                    alt="account-photo.svg"
                  />
                </VCol>
                <VCol cols="12" class="align-self-center">
                  <label for="customer_photo">
                    <VBtn :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`" @click="getFile">
                      {{ $t("profile.changeBtn") }}
                    </VBtn>
                  </label>
                </VCol>
              </VRow>
              <VFileInput
                id="customer_photo"
                class="d-none"
                v-model="customer.photo"
                accept="image/*"
                @change="makePreview"
              />
              <VRow v-if="photoPreview && !$vuetify.breakpoint.smAndDown" align="center">
                <VCol cols="3">
                  <img :src="photoPreview" alt="" width="80" class="img-preview"/>
                </VCol>
                <VCol cols="6">
                  <VBtn :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`" @click="onChangeImage" depressed>
                    {{ $t("btn.delete") }}
                  </VBtn>
                </VCol>
              </VRow>
              <VRow v-if="photoPreview && $vuetify.breakpoint.smAndDown" align="center">
                <VCol cols="12">
                  <img :src="photoPreview" alt="" width="80" class="img-preview"/>
                </VCol>
                <VCol cols="12">
                  <VBtn :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`" @click="onChangeImage" depressed>
                    {{ $t("btn.delete") }}
                  </VBtn>
                </VCol>
              </VRow>
            </VCol>
            <VRow class="align-center mt-3 justify-center">
            <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0" v-if="accessRole">
              <VTextField
                autocomplete="off"
                :label="$t('form.company')"
                dense
                outlined
                v-model="customer.company"
                color="green"
              />
            </VCol>
              <VCol cols="12" class="py-0" v-if="!accessRole">
              <VTextField
                autocomplete="off"
                :label="$t('form.company')"
                dense
                outlined
                v-model="customer.company"
                color="green"
              />
            </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0" v-if="accessRole">
                <v-autocomplete
                  :items="depots"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  outlined
                  :label="$t('form.storage')"
                  :no-data-text="$t('table.noData')"
                  dense
                  v-model="customer.deport_uuid"
                  :error-messages="uuidErrors"
                  :disabled="!permissions.can_delete_manager"
                  color="success"
                  item-color="success"
                  v-on:keypress="validationForWarehouses($event)"
                  @paste="validationForWarehouses($event)"
                />
              </VCol>
            <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
              <VTextField
                :label="$t('form.name')"
                dense
                color="green"
                outlined
                v-model="customer.name"
                :error-messages="nameErrors"
                :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                v-on:keypress="validationForName($event)"
                @paste="validationForName($event)"
              />
            </VCol>
            <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
              <VTextField
                :label="$t('form.surname')"
                dense
                outlined
                color="green"
                v-model="customer.surname"
                :error-messages="surnameErrors"
                :hint="onEngText ? '' :  $t('form.hint.SurnameNoEngText')"
                v-on:keypress="validationForSurname($event)"
                @paste="validationForSurname($event)"
              />
            </VCol>
            <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
              <VTextField
                class="inputPhone"
               :label="$t('form.phone_number')"
                dense
                color="green"
                prefix="+38"
                :maxLength="20"
                outlined
                v-model="customer.phoneNumber"
                :error-messages="phoneErrors"
                autocomplete="new-password"
                v-on:keypress="validationForPhoneNumber($event)"
                @paste="validationForPhoneNumber($event)"
              >
              </VTextField>
            </VCol>
            <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
              <VTextField
                :label="$t('form.email')"
                dense
                outlined
                v-model="customer.email"
                autocomplete="off"
                :error-messages="emailErrors"
                color="green"
                v-on:keypress="validationForEmail($event)"
                @paste="validationForEmail($event)"
              />
            </VCol>
            </VRow>
            <VRow class="align-center mt-3 justify-center">
              <VCol cols="12" class="d-block font-14 text-left font-weight-medium"
                    :style="`color: ${$vuetify.theme.dark ? '#fafafa' : '#545252'}`">
                {{ $t('form.tariff_data') }}
              </VCol>
            <VCol cols="12" class="py-0">
              <VMenu
                ref="datePikerDialog"
                v-model="datePikerDialog"
                :close-on-content-click="false"
                :return-value.sync="customer.date_crm_end"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <VTextField
                    v-model="customer.date_crm_end"
                    :label="$t('form.crm_end_date')"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    color="green"

                  ></VTextField>
                </template>
                <VDatePicker
                  v-model="customer.date_crm_end"
                  no-title
                  scrollable
                  :locale="currentLanguage.code"
                  :min="new Date().toISOString().substr(0, 10)"
                >
                  <VSpacer></VSpacer>
                  <VBtn text color="primary" @click="datePikerDialog = false">
                    Cancel
                  </VBtn>
                  <VBtn
                    text
                    color="primary"
                    @click="$refs.datePikerDialog.save(customer.date_crm_end)"
                  >
                    OK
                  </VBtn>
                </VDatePicker>
              </VMenu>
            </VCol>
            <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0" v-if="$route.name === 'clients'">
              <VTextField
                autocomplete="off"
                :label="$t('form.deliveries_balance')"
                dense
                outlined
                v-model="customer.deliveries_balance"
                color="green"
                :maxLength="10"
                v-on:keypress="validationForNumbers($event)"
                @paste="validationForNumbers($event)"
              />
            </VCol>
            <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0" v-if="$route.name === 'clients'">
              <VTextField
                autocomplete="off"
                :label="$t('form.deliveries_negative_limit')"
                dense
                outlined
                v-model="customer.deliveries_negative_limit"
                color="green"
                :maxLength="10"
                v-on:keypress="validationForNumbers($event)"
                @paste="validationForNumbers($event)"
              />
            </VCol>
            <VCol cols="12" v-if="$route.name === 'clients'">
                <VTextField
                  autocomplete="off"
                  :label="$t('form.balance')"
                  dense
                  outlined
                  v-model="customer.balance"
                  color="green"
                  :maxLength="10"
                  v-on:keypress="validationForNumbers($event)"
                  @paste="validationForNumbers($event)"
                />
              </VCol>
            </VRow>
            <VRow class="align-center mt-3 justify-center text-left">
              <VCol cols="12" class="d-block font-14 text-left font-weight-medium"
                    :style="`color: ${$vuetify.theme.dark ? '#fafafa' : '#545252'}`">
                {{$t('form.password')}}
              </VCol>
            <VCol cols="12" class="pb-5 pt-0">
              <VTextField
                outlined
                :label="$t('form.password')"
                dense
                v-model="customer.password"
                :type="passwordVisible ? 'text' : 'password'"
                :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordVisible = !passwordVisible"
                :error-messages="passwordErrors"
                autocomplete="new-password"
                hide-details="auto"
                color="green"
                v-on:keypress="validationForPassword($event)"
                @paste="validationForPassword($event)"
              />
            </VCol>
            <VCol cols="12" class="py-0">
              <VTextField
                outlined
                :label="$t('profile.сonfirm_pass')"
                dense
                v-model="customer.passwordRepeat"
                :type="repeatPasswordVisible ? 'text' : 'password'"
                :append-icon="repeatPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="repeatPasswordVisible = !repeatPasswordVisible"
                :error-messages="passwordRepeatErrors"
                hide-details="auto"
                color="green"
                v-on:keypress="validationForPassword($event)"
                @paste="validationForPassword($event)"
              />
            </VCol>
            </VRow>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VContainer>
          <VRow class="my-0 py-0">
            <VCol cols="12" class="my-0 py-0">
              <VBtn
                class="text-white text-transform-none w-100"
                :class="{ 'success-bg': isChanged, 'text-caption': $vuetify.breakpoint.xs }"
                block
                :disabled="!isChanged"
                @click="onCreate"
              >
                {{$t('btn.create')}}
              </VBtn>
            </VCol>
            <VCol cols="12" class="pb-0">
              <VBtn
                @click="onClose"
                block
                class="w-100 text-transform-none"
                dark
                :class="{ 'text-caption': $vuetify.breakpoint.xs }"
              >
               {{$t('btn.cancel')}}
              </VBtn>
            </VCol>
          </VRow>
        </VContainer>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
  </VDialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required, email, requiredIf, sameAs
} from "vuelidate/lib/validators";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import language from "../../mixins/language";
import dialogMixin from "../../mixins/dialogMixin";
import passwordMask from "../../validators/passwordMask";
import notifications from "../../mixins/notifications";
import usersService from "../../services/request/users/usersService";
import EventBus from "../../events/EventBus";
import depotService from "../../services/request/depot/depotService";
import user from "../../mixins/user";
import phoneMask from "@/validators/phoneMask";
import {
  validationForNumbers,
  validationForEmail,
  validationForPhoneNumber,
  validationForSurname,
  validationForName,
  validationForWarehouses, validationForPassword
} from "@/mixins/helpers";
import { mapGetters } from "vuex";
import noEngTextValidator from "@/validators/noEngText";
import fileSizeValidation from "@/validators/fileSizeAvatar";

export default {
  mixins: [language, dialogMixin, validationMixin, notifications, user],
  name: "AddClientDialog",
  validations: {
    customer: {
      name: { required, noEngTextValidator },
      surname: { required, noEngTextValidator },
      deport_uuid: {
        // eslint-disable-next-line func-names
        required: requiredIf(function() {
          return this.accessRole;
        })
      },
      phoneNumber: { phoneMask, required },
      email: { email, required },
      password: { required, passwordMask },
      passwordRepeat: { required, passwordMask, sameAsPassword: sameAs("password") },
      photo: { fileSizeValidation }
    }
  },
  components: {
    ConfirmLeftDialog
  },
  data: () => ({
    repeatPasswordVisible: false,
    onEngText: true,
    customer: {
      name: "",
      surname: "",
      phoneNumber: "",
      company: "",
      uuid_crm_apikey: "",
      email: "",
      password: "",
      passwordRepeat: "",
      photo: null,
      resetPhoto: false,
      date_crm_end: null,
      deliveries_negative_limit: "",
      deliveries_balance: "",
      balance: ""
    },
    visibleConfirm: false,
    depots: [],
    datePikerDialog: false,
    accessRole: false,
    photoPreview: null,
    passwordVisible: false,
    isChanged: false
  }),
  props: {
    addName: {
      required: false,
    }
  },
  watch: {
    customer: {
      deep: true,
      handler() {
        const values = Object.values(this.customer);
        if (values.filter(item => item).length) {
          this.isChanged = true;
        } else {
          this.isChanged = false;
        }
      }
    },
    resetPhoto: {
      deep: true,
      handler() {
        this.onResetImage()
      }
    }
  },
  async mounted() {
    this.updateRoleInfo();
    this.customer.deport_uuid = this.getUser.uuid_deport;
    if(this.permissions.can_edit_client) {
      try {
        this.depots = await depotService.getDepotsList();
      } catch (e) {
        console.log(e)
      }
    }
  },
  methods: {
    validationForPassword,
    validationForWarehouses,
    validationForName,
    validationForSurname,
    validationForPhoneNumber,
    validationForEmail,
    validationForNumbers,
    showConfirmDialog() {
      if (this.isChanged) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    onClose() {
      this.customer = [];
      this.visibility = false;
    },
    updateRoleInfo() {
      if (this.permissions.can_edit_client) {
        this.accessRole = true;
        // this.validations.user.deport_uuid.required = true;
      }
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const formData = new FormData();
          formData.append("name", this.customer.name);
          formData.append("surname", this.customer.surname);
          formData.append("email", this.customer.email);
          formData.append("company", this.customer.company);
          formData.append("password", this.customer.password);
          if (this.accessRole) {
            formData.append("uuid_deport", this.customer.deport_uuid);
          }
          if (this.customer.date_crm_end) {
            formData.append("date_crm_end", this.customer.date_crm_end);
          }
          formData.append("deliveries_balance", this.customer.deliveries_balance);
          formData.append("deliveries_negative_limit", this.customer.deliveries_negative_limit);
          formData.append("balance", this.customer.balance);
          formData.append("type", this.$route.name === 'crm-users' ? 'crm' : 'client');
          formData.append("phone", `+38${this.customer.phoneNumber}`);
          if (this.customer.photo) {
            formData.append("image", this.customer.photo);
          }
          await usersService.addUser(formData);
          EventBus.$emit("added-user");
          this.$emit("resetFilter", true);
          this.visibility = false;
          this.$v.$reset();
          this.clearCustomerData();
          this.resetPhoto = !this.resetPhoto
          this.setSuccessNotification(this.$t('users.user_add_success'));
        } catch (e) {
          this.setErrorNotification(e.error.description)
          console.log(e)
        }
      }
    },
    clearCustomerData() {
      Object.keys(this.customer).forEach(key => {
        this.customer[key] = null;
      });
    },
    onResetImage(){
      this.photoPreview = false
    },
    onChangeImage() {
      delete this.customer.photo
      this.photoPreview = null;
    },
    getFile() {
      const fileUpload = document.getElementById("customer_photo");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    makePreview() {
      if(this.$v.customer.photo.fileSizeValidation){
      let photoPreview = null;
      const { photo } = this.customer;
      const reader = new FileReader();
      reader.readAsDataURL(photo);
      reader.onloadend = ev => {
        photoPreview = ev.target.result;
        this.photoPreview = photoPreview;
      };
    } else{
      this.customer.photo = null;
      this.photoPreview = null;
      this.setErrorNotification(this.$t("form.errors.photoMaxSizeAvatar"));
    }
    }
  },
  computed: {
       ...mapGetters(["currentLanguage"]),
    uuidErrors() {
      const errors = [];
      if (!this.$v.customer.deport_uuid.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.deport_uuid.required && errors.push(this.$t('form.errors.StorageRequired'));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.customer.name.$dirty) {
        return errors;
      }
      if (!this.$v.customer.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.name.required && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },

    surnameErrors() {
      const errors = [];
      if (!this.$v.customer.surname.$dirty) {
        return errors;
      }
      if (!this.$v.customer.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.surname.required &&
        errors.push(this.$t('form.errors.SurnameRequired'));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.customer.phoneNumber.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.phoneNumber.required &&
        errors.push(this.$t('form.errors.PhoneNumberRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.phoneNumber.phoneMask &&
        errors.push(this.$t('form.errors.PhoneNumberMustBeLike'));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.customer.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.email.required &&
        errors.push(this.$t('form.errors.EmailRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.email.email && errors.push(this.$t('form.errors.EmailMustBeValid'));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.customer.password.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.password.required &&
        errors.push(this.$t('form.errors.PasswordRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.password.passwordMask && errors.push(this.$t('form.errors.PasswordMustBeLike'));
      return errors;
    },
    passwordRepeatErrors() {
      const errors = [];
      if (!this.$v.customer.passwordRepeat.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      if (!this.$v.customer.passwordRepeat.required) {
        errors.push(this.$t("form.errors.ConfirmPassRequired"));

        return errors;
      }
      if (!this.$v.customer.passwordRepeat.passwordMask) {
        errors.push(this.$t("form.errors.PasswordMustBeLike"));

        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.passwordRepeat.sameAsPassword &&
      errors.push(this.$t("form.errors.PasswordsDontMatch"));
      return errors;
    },
  }
};
</script>

<style scoped lang="scss">
.customer-photo-label:hover {
  i {
    opacity: 0.7;
  }
}
.img-preview {
  border-radius: 15%;
  border: 1px solid lightgray;
  min-width: 80px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
</style>
